import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './PaymentSummary.st.css';
import Text, { TextType } from '../../Text/Text';
import { ServicePayment } from '@wix/bookings-uou-types';
import { PaymentSummaryDataHooks } from './dataHooks';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../types/types';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { Divider } from 'wix-ui-tpa/Divider';
import { TagName } from '../../../../utils/tagName.const';
import { PriceSummaryItem } from './PriceSummaryItem/PriceSummaryItem';
import { PaymentDetails } from '../PaymentDetails/PaymentDetails';
import { getContent } from '../../../../utils/content/content';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { CouponDetails } from '@wix/ambassador-checkout-server/types';

export type PaymentSummaryProps = {
  servicePayment: ServicePayment;
  selectedPaymentOption: PaymentOption;
  dateRegionalSettingsLocale: string;
  numberOfParticipants: number;
  appliedCoupon?: CouponDetails;
};

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  servicePayment,
  selectedPaymentOption,
  dateRegionalSettingsLocale,
  numberOfParticipants,
  appliedCoupon,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const {
    price,
    priceText,
    currency,
    minCharge: deposit,
  } = servicePayment.paymentDetails;

  const isPricingPlanSelected =
    !selectedPaymentOption.disabled &&
    ![
      ReservedPaymentOptionIds.SingleSession,
      ReservedPaymentOptionIds.BuyAPricingPlan,
    ].includes(selectedPaymentOption.id as any);

  const showSingleSessionSummary = (): boolean => {
    const serviceHasPrice = price > 0 || !!priceText;
    return (
      serviceHasPrice &&
      selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession
    );
  };

  const shouldShowPaymentSummary = () => {
    return showSingleSessionSummary() || isPricingPlanSelected;
  };
  const couponDiscount = Number(appliedCoupon?.couponDiscount) || 0;
  const totalPrice = price * numberOfParticipants - couponDiscount;

  const showNumberOfParticipantsPrice = numberOfParticipants > 1;

  const singleSessionSummary = (
    <>
      {priceText ? (
        <div>
          <Text
            type={TextType.Secondary}
            data-hook={PaymentSummaryDataHooks.CUSTOM_PRICE}
          >
            {priceText}
          </Text>
        </div>
      ) : null}
      {showNumberOfParticipantsPrice ? (
        <PriceSummaryItem
          type={TextType.Secondary}
          label={t('app.payment.options.single-session.text')}
          description={`${numberOfParticipants} X ${PriceUtils.getFormattedCurrency(
            {
              price,
              locale: dateRegionalSettingsLocale,
              currency,
            },
          )}`}
        />
      ) : null}
      {couponDiscount ? (
        <PriceSummaryItem
          type={TextType.Secondary}
          label={t('app.payment.summary.promo-code.text')}
          description={PriceUtils.getFormattedCurrency({
            price: -couponDiscount,
            locale: dateRegionalSettingsLocale,
            currency,
          })}
        />
      ) : null}
      {price ? (
        <PriceSummaryItem
          type={TextType.Primary}
          label={t('app.payment.summary.total.text')}
          description={PriceUtils.getFormattedCurrency({
            price: totalPrice,
            locale: dateRegionalSettingsLocale,
            currency,
          })}
        />
      ) : null}
      {deposit ? (
        <PriceSummaryItem
          type={TextType.Primary}
          label={t('app.payment.summary.deposit.text')}
          description={PriceUtils.getFormattedCurrency({
            price: deposit,
            locale: dateRegionalSettingsLocale,
            currency,
          })}
        />
      ) : null}
    </>
  );

  return shouldShowPaymentSummary() ? (
    <div
      className={classes.root}
      data-hook={PaymentSummaryDataHooks.PAYMENT_SUMMARY}
    >
      <Divider className={classes.divider} />
      <Text
        tagName={TagName.H3}
        type={TextType.Primary}
        className={classes.title}
        data-hook={PaymentSummaryDataHooks.TITLE}
      >
        {getContent({
          settings,
          settingsParam: settingsParams.summaryPaymentSectionTitle,
        })}
      </Text>
      {showSingleSessionSummary() ? (
        singleSessionSummary
      ) : (
        <PaymentDetails paymentOption={selectedPaymentOption} />
      )}
    </div>
  ) : null;
};
