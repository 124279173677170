import React from 'react';
import { classes, st } from './BookButton.st.css';
import { BookButtonDataHooks } from './dataHooks';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Button, SIZE } from 'wix-ui-tpa/Button';
import { useFormActions } from '../../Hooks/useFormActions';
import { PaymentType } from '../../../../utils/mappers/service.mapper';
import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams from '../../settingsParams';
import { ActionLabels } from '@wix/ambassador-services-catalog-server/http';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { Error } from 'wix-ui-tpa/dist/src/assets/icons';
import { BookErrorType, FormErrors } from '../../../../types/errors';
import { FormStatus } from '../../../../utils/state/initialStateFactory';
import { Spinner } from 'wix-ui-tpa/Spinner';
import stylesParams from '../../stylesParams';
import { hasErrorOfType } from '../../../../utils/errors/errors';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../types/types';
import { getContent } from '../../../../utils/content/content';

export interface BookButtonProps {
  errors: FormErrors[];
  isPendingApprovalFlow: boolean;
  actionLabels: ActionLabels;
  paymentTypes: PaymentType[];
  status: FormStatus;
  selectedPaymentOption: PaymentOption;
}
const BookButton: React.FC<BookButtonProps> = ({
  errors,
  isPendingApprovalFlow,
  actionLabels,
  paymentTypes,
  selectedPaymentOption,
  status,
}) => {
  const { t } = useTranslation();
  const { onSubmit, submitForm } = useFormActions();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const actionLabel = getActionLabel(
    selectedPaymentOption,
    isPendingApprovalFlow,
    actionLabels,
    paymentTypes,
    settings,
  );
  const styles = useStyles();
  const fontSize = styles.get(stylesParams.mainButtonFont).size!;

  const shouldShowError = hasErrorOfType({ errors, errorType: BookErrorType });

  return (
    <div className={st(classes.root, { isMobile })}>
      <Button
        size={SIZE.medium}
        className={classes.bookButton}
        data-hook={BookButtonDataHooks.ACTION_BUTTON}
        upgrade
        aria-pressed={status === FormStatus.PROCESSING_BOOK_REQUEST}
        type="submit"
        fullWidth={settings.get(settingsParams.stretchButtonToFullWidth)}
        onClick={() => {
          const submissionResponse = submitForm?.();
          if (submissionResponse) {
            onSubmit(submissionResponse);
          }
        }}
      >
        {status === FormStatus.PROCESSING_BOOK_REQUEST ? (
          <div className={classes.spinnerWrapper} style={{ height: fontSize }}>
            <Spinner
              data-hook={BookButtonDataHooks.SPINNER}
              className={classes.spinner}
              diameter={fontSize}
            />
          </div>
        ) : (
          t(actionLabel)
        )}
      </Button>

      {shouldShowError ? (
        <SectionNotification
          type="error"
          className={st(classes.error)}
          data-hook={BookButtonDataHooks.ERROR}
        >
          <SectionNotification.Icon icon={<Error />} />
          <SectionNotification.Text>
            {t('app.server-errors.slot-not-available')}
          </SectionNotification.Text>
        </SectionNotification>
      ) : null}
    </div>
  );
};

const getActionLabel = (
  selectedPaymentOption: PaymentOption,
  isPendingApprovalFlow: boolean,
  actionLabels: ActionLabels,
  paymentTypes: PaymentType[],
  settings: ISettingsContextValue,
): string => {
  if (selectedPaymentOption.id === ReservedPaymentOptionIds.BuyAPricingPlan) {
    return getContent({
      settings,
      settingsParam: settingsParams.chooseAPlanText,
    });
  }
  if (isPendingApprovalFlow) {
    return (
      settings.get(settingsParams.requestBookingButtonText) ||
      actionLabels?.bookingRequestApprovalLabel!
    );
  } else if (canBePaidOnline(paymentTypes)) {
    return (
      settings.get(settingsParams.onlinePaymentButtonText) ||
      actionLabels?.onlinePaymentLabel!
    );
  } else {
    return (
      settings.get(settingsParams.offlinePaymentButtonText) ||
      actionLabels?.offlinePaymentLabel!
    );
  }
};

const canBePaidOnline = (paymentTypes: PaymentType[]) =>
  paymentTypes?.length === 1 && paymentTypes?.includes(PaymentType.ONLINE);

export default BookButton;
