import React, { useEffect } from 'react';
import { classes, st } from './FormInputs.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  FormFieldViewInfoFieldType,
  FormRef,
  FormView,
  MessageType,
  SubmissionResponse,
  SubmissionValue,
  TranslateArgs,
} from '@wix/forms-ui/types';
import { Form } from '@wix/forms-ui/tpa';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { FormInputsDataHooks } from './dataHooks';
import { Member } from '@wix/ambassador-members-ng-api/types';
import {
  BookingRequestKeyMappings,
  mapMemberDetailsToDisplayPropertiesValues,
  mapSchemaToDisplayPropertiesDisabledStatus,
} from '../../../../utils/mappers/form-submission.mapper';
import { useFormActions } from '../../Hooks/useFormActions';
import { DesignTabSubSettings } from '../../../../types/types';
import {
  createDummyEmptySubmission,
  createDummySubmission,
} from '../../../../utils/dummies/dummy-data';
import { useEditorContext } from '../../Hooks/useEditorContext';
import { FormStatus } from '../../../../utils/state/initialStateFactory';

export interface FormInputsProps {
  formSchema: FormView;
  memberDetails?: Member;
  formRef: React.MutableRefObject<FormRef | undefined>;
  status: FormStatus;
  overideDefaultFieldsValues?: boolean;
}

export type SubmitForm = () => SubmissionResponse | undefined;

const FormInputs: React.FC<FormInputsProps> = ({
  formSchema,
  memberDetails,
  formRef,
  status,
  overideDefaultFieldsValues = false,
}) => {
  const { setNumberOfParticipants } = useFormActions();
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const editorContext = useEditorContext();

  const fillInMemberDetails = (memberDetails: Member) => {
    formRef!.current!.setFieldDisplayProperties(
      mapMemberDetailsToDisplayPropertiesValues(
        [
          BookingRequestKeyMappings.EMAIL,
          BookingRequestKeyMappings.PHONE,
          BookingRequestKeyMappings.FIRST_NAME,
          BookingRequestKeyMappings.LAST_NAME,
          BookingRequestKeyMappings.FULL_ADDRESS,
        ],
        formSchema,
        memberDetails,
        overideDefaultFieldsValues,
      ),
    );
  };

  const updateDummyContent = () => {
    formRef!.current!.setFieldDisplayProperties(
      editorContext.selectedSettingsSubTabId === DesignTabSubSettings.TEXT
        ? createDummySubmission(t)
        : createDummyEmptySubmission(),
    );
  };

  const setFieldsDisableState = (disabled: boolean) => {
    formRef!.current!.setFieldDisplayProperties(
      mapSchemaToDisplayPropertiesDisabledStatus(disabled, formSchema),
    );
  };

  useEffect(() => {
    if (memberDetails) {
      fillInMemberDetails(memberDetails);
    }
  }, [memberDetails]);

  useEffect(() => {
    if (editorContext.isDummy) {
      updateDummyContent();
    }
  }, [editorContext]);

  useEffect(() => {
    setFieldsDisableState(status === FormStatus.PROCESSING_BOOK_REQUEST);
  }, [status]);

  const getInvalidPatternByType = (type?: FormFieldViewInfoFieldType) => {
    switch (type) {
      case FormFieldViewInfoFieldType.EMAIL:
        return t('app.form-inputs.validation-errors.invalid-email');
      case FormFieldViewInfoFieldType.PHONE_COUNTRY_CODE:
        return t('app.form-inputs.validation-errors.invalid-phone');
    }
    return '';
  };

  const getValidationErrorMessage = ({
    type,
    context: { renderInfo },
  }: TranslateArgs) => {
    switch (type) {
      case MessageType.VALUE_REQUIRED:
        return t('app.form-inputs.validation-errors.required-field');
      case MessageType.INVALID_PATTERN:
        return getInvalidPatternByType(renderInfo?.type);
      default:
        return '';
    }
  };

  const participantsNumberField = formSchema.fields?.find(
    (field) =>
      field?.renderInfo?.metadata?.bookingsKeyMapping?.key ===
      BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  );

  const onChangeInputField = (externalId: string, value: SubmissionValue) => {
    if (participantsNumberField?.externalId === externalId) {
      setNumberOfParticipants(Number(value));
    }
  };
  const theme = settings.get(settingsParams.fieldsBorderStyle);
  return (
    <Form
      isMobile={isMobile}
      ref={formRef}
      className={st(classes.root, { isMobile, theme })}
      data-hook={FormInputsDataHooks.FORM_COMPONENT}
      translateMessage={getValidationErrorMessage}
      theme={theme}
      schema={formSchema}
      onChange={(externalId: string, value: SubmissionValue) => {
        onChangeInputField(externalId, value);
      }}
    />
  );
};
export default FormInputs;
