import React, { FC, useRef, useMemo, useEffect } from 'react';
import {
  WidgetProps,
  useTranslation,
  useEnvironment,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { st, classes } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { FormRef, SubmissionResponse } from '@wix/forms-ui/types';
import FormInputs from './FormInputs/FormInputs';
import { UserSelection } from './UserSelection/UserSelection';
import {
  FormState,
  FormStatus,
} from '../../../utils/state/initialStateFactory';
import BookingDetails from './BookingDetails/BookingDetails';
import { Header } from './Header/Header';
import BookButton from './BookButton/BookButton';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import EmptyStatePage from './EmptyStatePage/EmptyStatePage';
import { EditorContextProvider } from '../Hooks/useEditorContext';
import { EmptyStateErrorType, GenericErrorType } from '../../../types/errors';
import Toast from './Toast/Toast';
import { PaymentSelection } from './PaymentSelection/PaymentSelection';
import BackButton from './BackButton/BackButton';
import { getPaymentOptions } from '../../../utils/payment/payment';
import { WidgetDataHooks } from './dataHooks';
import Coupon from './Coupon/Coupon';
import { PaymentSummary } from './PaymentSummary/PaymentSummary';
import { PaymentOption } from '../../../types/types';
import { getErrorByType } from '../../../utils/errors/errors';

export type FormComponentActions = {
  submitForm?: () => SubmissionResponse;
};
export type FormActions = FormControllerActions & FormComponentActions;

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  actions,
  service,
  slotAvailability,
  businessInfo,
  numberOfParticipants,
  pricingPlanDetails,
  isPricingPlanInstalled,
  selectedPaymentOptionId,
  couponInfo,
  memberDetails,
  errors,
  editorContext,
  status,
  overideDefaultFieldsValues,
}) => {
  useEffect(() => {
    if (status === FormStatus.INITIALIZING) {
      actions.initializeWidget();
    }
  }, []);

  const formRef = useRef<FormRef>();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const submitForm = () => formRef?.current?.submit();
  const settings = useSettings();
  const slot = slotAvailability?.slot!;
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const emptyStateErrors = [
    EmptyStateErrorType.INVALID_SERVICE_ID,
    EmptyStateErrorType.INVALID_SLOT_AVAILABILITY,
    EmptyStateErrorType.INVALID_CATALOG_DATA,
    EmptyStateErrorType.COURSE_WITHOUT_SESSIONS,
  ];

  const processingStatuses = [
    FormStatus.INITIALIZING,
    FormStatus.PROCESSING_USER_DETAILS,
    FormStatus.SSR,
  ];
  const isProcessing = processingStatuses.includes(status);

  const shouldShowEmptyStatePage = () =>
    isProcessing || emptyStateErrors.some((error) => errors.includes(error));

  const dateAndTimeFormatter = useMemo(
    () => new DateTimeFormatter(dateRegionalSettingsLocale),
    [dateRegionalSettingsLocale],
  );

  const paymentOptions: PaymentOption[] = useMemo(
    () =>
      getPaymentOptions({
        servicePayment: service?.payment,
        pricingPlanDetails,
        isPricingPlanInstalled,
        dateAndTimeFormatter,
        numberOfParticipants,
        dateRegionalSettingsLocale,
        t,
        settings,
      }),
    [
      service?.payment,
      pricingPlanDetails,
      dateAndTimeFormatter,
      isPricingPlanInstalled,
      numberOfParticipants,
      dateRegionalSettingsLocale,
      t,
      settings,
    ],
  );

  const selectedPaymentOption = paymentOptions.find(
    (paymentOption) => paymentOption.id === selectedPaymentOptionId,
  )!;

  if (shouldShowEmptyStatePage()) {
    return <EmptyStatePage isProcessing={isProcessing} />;
  }

  const toastError = getErrorByType({
    errors,
    errorType: GenericErrorType,
  });

  return (
    <EditorContextProvider value={editorContext} key="form-main-widget">
      <FormActionsProvider value={{ ...actions, submitForm }}>
        <div
          className={st(classes.root, { isMobile })}
          data-hook={WidgetDataHooks.MAIN_CONTAINER}
        >
          <BackButton />
          <div className={classes.body}>
            {toastError ? (
              <Toast
                toastError={toastError}
                numberOfParticipants={numberOfParticipants}
              />
            ) : null}
            <div className={classes.formWrapper}>
              <Header {...service.formHeader} />
              <UserSelection memberDetails={memberDetails} />
              <FormInputs
                formSchema={service.formSchema}
                formRef={formRef}
                memberDetails={memberDetails}
                status={status}
                overideDefaultFieldsValues={overideDefaultFieldsValues}
              />
              <PaymentSelection
                paymentOptions={paymentOptions}
                selectedPaymentOptionId={selectedPaymentOptionId}
                numberOfParticipants={numberOfParticipants}
              />
            </div>
            <div className={classes.sidebar}>
              <div className={classes.floatingContainer}>
                <BookingDetails
                  service={service}
                  startDate={slot.startDate!}
                  endDate={slot.endDate!}
                  dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                />
                <Coupon
                  couponInfo={couponInfo}
                  servicePayment={service.payment}
                  selectedPaymentOptionId={selectedPaymentOptionId}
                  errors={errors}
                />
                <PaymentSummary
                  dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                  servicePayment={service.payment}
                  selectedPaymentOption={selectedPaymentOption}
                  numberOfParticipants={numberOfParticipants}
                  appliedCoupon={couponInfo.appliedCoupon}
                />
                <BookButton
                  isPendingApprovalFlow={service.isPendingApprovalFlow}
                  actionLabels={service.actionLabels!}
                  paymentTypes={service.paymentTypes}
                  selectedPaymentOption={selectedPaymentOption}
                  status={status}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>
      </FormActionsProvider>
    </EditorContextProvider>
  );
};

export default Widget;
