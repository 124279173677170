import React from 'react';
import { SettingsTab } from '../../../types/types';

export interface SettingsNavigationContextParams {
  currentTabId?: SettingsTab;
  setCurrentTabId?: (settingsTab: SettingsTab) => void;
  openBackOfficeForm?: () => void;
  selectedSubTab?: string;
  setSelectedSubTab?: () => void;
}

export const SettingsTabsContext =
  React.createContext<SettingsNavigationContextParams>({
    currentTabId: SettingsTab.Manage,
    setCurrentTabId: (settingsTab: SettingsTab) => {},
    openBackOfficeForm: () => {},
    selectedSubTab: undefined,
    setSelectedSubTab: () => {},
  });
export const SettingsTabsContextProvider = SettingsTabsContext.Provider;
