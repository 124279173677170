export enum CouponDataHooks {
  DIVIDER = 'coupon-divider',
  COUPON_TEXT_BUTTON = 'coupon-text-button',
  COUPON_INPUT = 'coupon-input',
  ACTION_BUTTON = 'coupon-action-button',
  ADD_BUTTON = 'coupon-action-button',
  REMOVE_BUTTON = 'coupon-action-button',
  ALREADY_USED_COUPON = 'coupon-already-used',
  ACCESSIBILITY_TAG = 'coupon-accessibility-tag',
}
